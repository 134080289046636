'use client';

import style from './style.module.css';
import Image from 'next/image';
import Link from 'next/link';
import classNames from 'classnames';
import Button from 'components/Button';

import Logo from 'public/images/cleeai-logo.svg?url';

interface Props {
  isLogoVisible?: boolean;
}

export default function Navbar({ isLogoVisible = true }: Props) {
  return (
    <nav
      className={classNames(
        {
          [style.navTopLogo]: isLogoVisible,
        },
        {
          [style.navbar]: !isLogoVisible,
        },
      )}
    >
      <div className={style.container}>
        <div className={style.leftArea}>
          {isLogoVisible && (
            <Link href="/">
              <Image src={Logo} className={style.navLogoUnauth} alt="Logo" />
            </Link>
          )}
        </div>
        <div className={style.rightArea}>
          <div
            className={classNames(style.unauthNavRight, {
              [style.navMWithLogo]: isLogoVisible,
            })}
          >
            <Button
              type="submit"
              variant="primary"
              href="/create-account"
              className={classNames(style.createAccountBtn, {
                [style.createAccountBtnWithLogo]: !isLogoVisible,
              })}
            >
              Sign up
            </Button>
            <Button
              type="submit"
              variant="secondary"
              href="/login"
              className={style.createAccountBtn}
            >
              Sign in
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
}
