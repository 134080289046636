'use client';

import style from './style.module.css';
import { MouseEvent } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import hamburgerMenu from 'public/images/icons/hamburgerMenu.svg?url';
import Button from 'components/Button';
import Logo from 'public/images/cleeai-logo.svg?url';
import { useSearchProvider } from 'providers/SearchProvider';

interface NavbarMobileProps {
  openNavCB: (e: MouseEvent) => void;
}

export function NavbarMobile({ openNavCB }: NavbarMobileProps) {
  const searchProvider = useSearchProvider();

  return (
    <nav className={style.navbarMobileAuth}>
      <div className={style.container}>
        <div className={style.leftAreaMobileAuth}>
          <Image
            src={hamburgerMenu}
            className={style.hamburgerMenu}
            onClick={openNavCB}
            alt="Hamburger Menu"
          />
          <Link href="/">
            <Image src={Logo} className={style.authMobileNavLogo} alt="Logo" />
          </Link>
        </div>
        <div className={style.rightArea}>
          <div className={style.navMWithLogo}>
            <Button
              type="submit"
              variant="primary"
              className={style.discoverBtnMobile}
              disabled={searchProvider?.discovery.isDisabled()}
              onClick={() => searchProvider?.discovery.perform()}
            >
              {searchProvider?.discovery.getButtonText()}
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
}
