import { ChangeEvent, FormEvent } from 'react';
import style from './style.module.css';
import Image from 'next/image';
import searchIcon from 'public/images/icons/searchBlack.svg?url';
import fatArrowForwardWhiteIcon from 'public/images/icons/fatArrowForwardWhite.svg?url';
import Button from 'components/Button';

interface Props {
  placeholderText?: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  onSubmitCB: (ev: FormEvent<HTMLFormElement>) => void;
  onChangeCB: (ev: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export default function SearchBox({
  readOnly = false,
  placeholderText = 'What do you want to research today?',
  isDisabled = false,
  value,
  onSubmitCB,
  onChangeCB,
}: Props) {
  return (
    <div className={style.formContainer}>
      <div className={style.inputBox}>
        <form className={style.form} onSubmit={onSubmitCB}>
          <div className={style.formRow}>
            <div className={style.inputContainer}>
              <Image
                src={searchIcon}
                alt="Search Icon"
                className={style.searchIcon}
              />
              <input
                name="queryText"
                type="text"
                placeholder={placeholderText}
                className={style.input}
                readOnly={readOnly}
                onChange={onChangeCB}
                value={value}
                disabled={isDisabled}
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = placeholderText)}
              />
            </div>
            <Button
              type="submit"
              variant="primary"
              className={style.submitBtn}
              disabled={isDisabled}
            >
              <Image
                src={fatArrowForwardWhiteIcon}
                alt="Forward arrow icon"
                className={style.submitIcon}
              />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
