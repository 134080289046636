import style from './style.module.css';

import { useMemo } from 'react';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';
import { useStateItem } from 'providers/stateItem';
import { useSearchProvider } from 'providers/SearchProvider';
import { emailConstraint } from 'utils/validationConstraints';
import { sendEmail } from 'utils/sendEmail';
import { toast } from 'sonner';
import useDeviceAndOrientation from 'hooks/useDeviceAndOrientation';

import Image from 'next/image';
import Modal from 'components/Modal';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';

import copyIconWhite from 'public/images/icons/contentCopyWhite.svg?url';
import mailIconWhite from 'public/images/icons/mailWhite.svg?url';
import shareIcon from 'public/images/icons/share.svg?url';

interface InviteFriendsModalProps {
  isModalOpen: boolean;
  onCloseCB: () => void;
}

export default function InviteFriendsModal({
  isModalOpen,
  onCloseCB,
}: InviteFriendsModalProps) {
  const [user] = useAuth();
  const SearchProvider = useSearchProvider();
  const email = useStateItem('');
  const error = useStateItem('');
  const isLoading = useStateItem(false);
  const inviteLink = SearchProvider?.share.generateInviteLink();
  const isMobile = useDeviceAndOrientation()['device'] === 'mobile';
  const iconsHight = isMobile ? 30 : 20;
  const inviterName =
    user?.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : `${user?.userName}`;

  const emailMessageBody = useMemo(() => {
    if (typeof window === 'undefined') return '';
    return `
    <p>Hey there 👋,</p>
    <p>${inviterName} wants you to sign up to CleeAI and join the millions of professionals using AI-powered search to get to your 'aha' moment faster.</p>
    <ul>
      <li>Become insanely productive ⚡</li>
      <li>Get high-quality responses 😊</li>
      <li>Avoid endless searching 👍</li>
    </ul>
    <p><a href="www.cleeai.com">Claim your free account</a> before it expires and experience the future.</p>
    <p>Remember to thank ${inviterName}!</p>
    <p>CleeAI<br>
    Ask anything. Trust everything.<br>
    AI-Powered Answer Engine.<br>
    <a href="www.cleeai.com">www.cleeai.com</a></p>
  `;
  }, [inviterName]);

  const validateEmail = () => {
    const validation = emailConstraint.safeParse(email.get());

    if (!validation.success) {
      const errors = validation?.error?.flatten();
      error.set(errors?.formErrors[0]);

      return false;
    }
    error.set('');
    return true;
  };

  const onChangeCB = (ev: React.ChangeEvent<HTMLInputElement>) => {
    Boolean(error.get()) && error.set('');
    email.set(ev.target.value);
  };

  const handleSendInvite = async () => {
    const emailAddress = email.get();
    Boolean(error.get()) && error.set('');
    isLoading.set(true);

    if (emailAddress === user?.userName) {
      error.set('Sorry, you cannot invite yourself.');
      isLoading.set(false);
      return;
    }

    const isValid = validateEmail();
    if (!isValid) {
      isLoading.set(false);
      return;
    }

    try {
      await sendEmail(
        emailAddress,
        `'Skip the Queue' invite from ${inviterName}`,
        emailMessageBody,
      );
      toast.success(
        `Invite email to ${email.get()} successfully sent. Thank you.`,
      );
      email.set('');
      analytics.invitedUser(user, [email.get()]);
    } catch (error) {
      error.set(error);
      analytics.generic(
        user,
        'User email invite from invite friends modal failed',
      );
    } finally {
      isLoading.set(false);
    }
  };

  const closeModal = () => {
    Boolean(email.get()) && email.set('');
    Boolean(error.get()) && error.set('');
    onCloseCB();
    analytics.generic(user, 'Invite Friends modal closed');
  };

  return (
    <>
      <Modal
        className={style.modal}
        isOpen={isModalOpen}
        onCloseCB={closeModal}
        title="Invite Friends to CleeAI"
      >
        <div className={style.modalBody}>
          <Label htmlFor="inviteLink">Invitation Link</Label>
          <div className={style.inputContainer}>
            <Input
              type="text"
              name="inviteLink"
              value={inviteLink ?? ''}
              className={style.input}
              readOnly
              onChangeCB={() => undefined}
            ></Input>
            <Button
              className={style.btn}
              onClick={() => {
                SearchProvider?.share.shareInvite();
                analytics.sharedInviteLink(user);
              }}
            >
              <Image
                src={isMobile ? shareIcon : copyIconWhite}
                alt="Copy Icon"
                height={iconsHight}
              ></Image>
              {isMobile ? '' : 'Copy link'}
            </Button>
          </div>
          <Label htmlFor="inviteLink">Invite by Email</Label>
          <p className={style.inviteParagraph}>
            Type emails, separated by space or comma
          </p>
          <div className={style.inputContainer}>
            <Input
              type="text"
              name="inviteLink"
              value={email.get()}
              className={style.input}
              onChangeCB={onChangeCB}
            ></Input>
            <Button
              className={style.btn}
              onClick={handleSendInvite}
              disabled={isLoading.get()}
            >
              <Image
                src={mailIconWhite}
                alt="Mail Icon"
                height={iconsHight}
              ></Image>
              {isMobile ? '' : 'Send Invite'}
            </Button>
          </div>
          {Boolean(error) && (
            <ErrorMessage className={style.errorMessage}>
              {error.get()}
            </ErrorMessage>
          )}
        </div>
      </Modal>
    </>
  );
}
