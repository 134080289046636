'use client';
import { MouseEvent } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Button from 'components/Button';
import TagContainer from './tagContainer';
import EmptyState from './emptyState';
import { fetchWithCredentials } from 'utils/fetchWrapper';
import isMobile from 'is-mobile';

import { useEffect, useState } from 'react';

import Logo from 'public/images/cleeai-logo.svg?url';
import closeIcon from 'public/images/icons/close.svg?url';
import addIconBlue from 'public/images/icons/groupAddBlue.svg?url';
import searchIcon from 'public/images/icons/searchWhite.svg?url';
import compareIcon from 'public/images/icons/llm-compare.svg?url';

import style from './style.module.css';

import { useSearchProvider } from 'providers/SearchProvider';
import { useStateItem } from 'providers/stateItem';
import { usePathname } from 'next/navigation';
import InviteFriendsModal from 'components/InviteFriendsModal';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';
import SearchModal from 'components/SearchModal';
import LLMComparisonModal from 'components/LlmComparisonModal';
import { isDevOrLocalhost } from 'utils/helpers';

interface SidebarProps {
  closeNavCB: (e: MouseEvent) => void;
}

const fetchLLMCompareAllowList = async () => {
  try {
    const response = await fetchWithCredentials(
      `${process.env.NEXT_PUBLIC_SERVER_BASE_URL}/utils/fetch-llm-compare-allow-list`,
    );

    if (!response.ok) {
      return [];
    }

    return response.json();
  } catch (err) {
    return [];
  }
};

export function Sidebar({ closeNavCB }: SidebarProps) {
  const [allowList, setAllowList] = useState<string[]>([]);

  useEffect(() => {
    if (!isDevOrLocalhost()) {
      return;
    }

    fetchLLMCompareAllowList().then((data) => {
      const allowList = data?.data?.allowList.allowedUsers || [];
      setAllowList(allowList);
    });
  }, []);

  const [user] = useAuth();

  // check whether user is present in allowlist
  const showLLMComparison =
    isDevOrLocalhost() &&
    !isMobile() &&
    user?.userName &&
    allowList.includes(user.userName);

  const SearchProvider = useSearchProvider();
  const isStreaming = SearchProvider?.search.getIsStreaming();
  const taggedSearches = SearchProvider?.savedSearches.get() || [];
  const isInviteModalOpen = useStateItem(false);
  const isSearchModalOpen = useStateItem(false);
  const isLLMComparisonModalOpen = useStateItem(false);
  const pathName = usePathname();
  const isHome = pathName == '/';

  return (
    <div className={style.container}>
      <Image
        src={closeIcon}
        className={style.closeIcon}
        alt="Close Icon"
        onClick={closeNavCB}
      />
      <Link href="/">
        <Image src={Logo} className={style.logoSidebar} alt="Logo" />
      </Link>
      {!isHome && (
        <>
          <Button
            variant="tertiary"
            onClick={() => {
              isSearchModalOpen.set(true);
              analytics.generic(user, 'New Search clicked');
            }}
            className={style.searchButton}
            disabled={isStreaming}
          >
            <Image src={searchIcon} alt="Search Icon"></Image>
            New Search
          </Button>

          {showLLMComparison && (
            <Button
              variant="tertiary"
              onClick={() => {
                isLLMComparisonModalOpen.set(true);
                analytics.generic(user, 'LLM comparison clicked');
              }}
              className={style.llmCompareButton}
              disabled={isStreaming}
            >
              <Image src={compareIcon} alt="Compare Icon"></Image>
              Compare LLMs
            </Button>
          )}
        </>
      )}
      <Button
        variant="tertiary"
        onClick={() => {
          isInviteModalOpen.set(true);
          analytics.generic(user, 'Invite Friends button clicked');
        }}
        className={style.inviteBtn}
      >
        <Image src={addIconBlue} alt="Add Friends Icon"></Image>
        Invite friends
      </Button>
      <p className={style.subHeading}>Previous Searches</p>
      {taggedSearches.length ? (
        <div className={style.searchItemsList}>
          <ul className={style.tagSearches}>
            {taggedSearches.map((tagSearch) => (
              <li key={tagSearch.tag}>
                <TagContainer
                  searches={tagSearch.searches}
                  tag={tagSearch.tag}
                  closeNavCB={closeNavCB}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <EmptyState />
      )}
      <InviteFriendsModal
        isModalOpen={isInviteModalOpen.get()}
        onCloseCB={() => isInviteModalOpen.set(false)}
      />
      {!isHome && (
        <>
          <SearchModal
            isModalOpen={isSearchModalOpen.get()}
            onCloseCB={() => isSearchModalOpen.set(false)}
            isSearchModalOpen={isSearchModalOpen}
          />
          {showLLMComparison && (
            <LLMComparisonModal
              isModalOpen={isLLMComparisonModalOpen.get()}
              onCloseCB={() => isLLMComparisonModalOpen.set(false)}
            />
          )}
        </>
      )}
    </div>
  );
}
