'use client';
import { useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import chevronRight from 'public/images/icons/chevronRight.svg?url';
import trash from 'public/images/icons/trash.svg?url';
import { useRouter } from 'next/navigation';

import style from './style.module.css';
import { SearchLabel } from 'lib/types';
import { usePathname } from 'next/navigation';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';
import { useSearchProvider } from 'providers/SearchProvider';

import Modal from 'components/Modal';
import Button from 'components/Button';

// we truncate search title if it's too long (more than 15 characters) so that,
// it doesn't get wrapped to the next line in previous searches sidebar
const MAX_NUM_OF_CHARS_IN_SEARCH_TITLE = 15;

function isActiveSearch(pathName: string, searchId: string | null) {
  return searchId ? pathName.includes(searchId) : false;
}

interface TagContainerProps {
  searches: SearchLabel[];
  tag: string;
  closeNavCB?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export default function TagContainer({
  tag,
  searches,
  closeNavCB,
}: TagContainerProps) {
  const pathName = usePathname();
  const [isOpen, setIsOpen] = useState(true);
  const [searchBeingDeleted, setSearchBeingDeleted] =
    useState<SearchLabel | null>(null);
  const [user] = useAuth();
  const searchProvider = useSearchProvider();
  const router = useRouter();

  return (
    <div className={style.tagSearch}>
      <div
        className={style.tag}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className={style.tagText}>#{tag}</span>
        <Image
          className={classNames(style.chevron, {
            [style.chevronOpen]: isOpen,
          })}
          src={chevronRight}
          alt="Chevron"
        ></Image>
      </div>
      <ul
        className={classNames(style.searchList, {
          [style.searchListShow]: isOpen,
        })}
      >
        {searches.map((search) => (
          <li key={search.id}>
            <Link
              href={`/search/${search.queryId}`}
              className={classNames(style.search, {
                [style.activeSearch]: isActiveSearch(pathName, search.queryId),
              })}
              onClick={(ev) => {
                if (closeNavCB) closeNavCB(ev);
                if (search.queryId) searchProvider?.loadSearch(search.queryId);
                //reset inputs error messages follow-up question input value on navigation to other search
                Boolean(searchProvider?.searchForm.getErrorMessage()) &&
                  searchProvider?.searchForm.setErrorMessage('');
                Boolean(
                  searchProvider?.followUpQuestionForm.getErrorMessage(),
                ) && searchProvider?.followUpQuestionForm.setErrorMessage('');
                Boolean(searchProvider?.followUpQuestionForm.getInputValue()) &&
                  searchProvider?.followUpQuestionForm.setInputValue('');
                analytics.clickedPreviousSearch(user, search);
              }}
            >
              {search.title.length > MAX_NUM_OF_CHARS_IN_SEARCH_TITLE // truncate search title if it's too long
                ? `${search.title.slice(0, MAX_NUM_OF_CHARS_IN_SEARCH_TITLE)}...`
                : search.title}
            </Link>
            <Image
              className={style.deleteIcon}
              src={trash}
              alt="delete search"
              height={13}
              onClick={() => {
                setSearchBeingDeleted(search);
                analytics.generic(user, 'Delete icon clicked', { search });
              }}
            />
          </li>
        ))}
      </ul>
      <Modal
        className={style.modal}
        isOpen={searchBeingDeleted !== null}
        onCloseCB={() => setSearchBeingDeleted(null)}
        title="Delete Search"
      >
        Are you sure you want to delete this search:{' '}
        <span className={style.deletePopupSearchTitle}>
          {searchBeingDeleted?.title}
        </span>
        <div className={style.deletePopupButtonsContainer}>
          <Button
            className={style.deletePopupButton}
            onClick={() => {
              if (searchBeingDeleted?.queryId) {
                analytics.generic(user, 'Deleted chat', { searchBeingDeleted });
                searchProvider?.savedSearches.remove(
                  searchBeingDeleted.queryId,
                );
              }
              setSearchBeingDeleted(null);
              if (isActiveSearch(pathName, searchBeingDeleted?.queryId ?? '')) {
                router.push('/');
              }
            }}
          >
            Delete
          </Button>
          <Button
            className={style.deletePopupButton}
            onClick={() => {
              setSearchBeingDeleted(null);
              analytics.generic(user, 'Delete chat - cancel clicked', {
                searchBeingDeleted,
              });
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
}
