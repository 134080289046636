import style from './style.module.css';

import { useSearchProvider } from 'providers/SearchProvider';
import { StateItem } from 'providers/stateItem';

import Modal from 'components/Modal';
import SearchBox from 'components/SearchBox';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import ErrorMessage from 'components/ErrorMessage';

interface SearchModalProps {
  isModalOpen: boolean;
  onCloseCB: () => void;
  isSearchModalOpen: StateItem<boolean>;
}

export default function SearchModal({
  isModalOpen,
  onCloseCB,
  isSearchModalOpen,
}: SearchModalProps) {
  const [user] = useAuth();
  const searchProvider = useSearchProvider();

  const closeModal = () => {
    onCloseCB();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onCloseCB={closeModal}
      className={style.searchModal}
      showCloseButton={false}
      title="New Search"
    >
      <SearchBox
        onSubmitCB={async (ev) => {
          isSearchModalOpen.set(false);
          await searchProvider?.searchForm.onSubmit(ev);
          analytics.questionAsked(
            user,
            searchProvider?.searchForm.getSearchText(),
          );
        }}
        onChangeCB={(ev) => searchProvider?.searchForm.onTextChanged(ev)}
        value={searchProvider?.searchForm.getSearchText() ?? ''}
        isDisabled={searchProvider?.searchForm.isDisabled() ?? false}
        placeholderText="Ask a question or Research a topic..."
      />
      {Boolean(searchProvider?.searchForm.getErrorMessage()) && (
        <ErrorMessage className={style.errorMessage}>
          {searchProvider?.searchForm.getErrorMessage()}
        </ErrorMessage>
      )}
    </Modal>
  );
}
