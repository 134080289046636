'use client';
import { useRef, useState } from 'react';

import style from './style.module.css';
import Button from 'components/Button';
import useAuth from 'hooks/useAuth';
import { ServerTypes } from 'lib/types';
import { useSearchProvider } from 'providers/SearchProvider';
import { QuestionTooltip } from './questionTooltip';
import AccountSettingsModal from 'containers/AccountSettingsModal';
import useDeviceAndOrientation from 'hooks/useDeviceAndOrientation';
import classNames from 'classnames';

function getUserInitials(
  user: Partial<ServerTypes.UserWithProfessions> | null,
) {
  if (user) {
    const { firstName, lastName, userName } = user;
    const isFullName = firstName && lastName;
    return (
      isFullName
        ? firstName[0] + lastName[0]
        : userName
          ? userName.slice(0, 2)
          : 'NA'
    ).toUpperCase();
  }
}

function getUserName(user: Partial<ServerTypes.UserWithProfessions> | null) {
  if (user) {
    const { firstName, lastName, userName } = user;
    const isFullName = firstName && lastName;
    return isFullName
      ? firstName + ' ' + lastName
      : userName
        ? userName
        : 'User';
  }
}

export function ChatInputBoxAuth() {
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState(false);
  const [user] = useAuth();
  const searchProvider = useSearchProvider();
  const isMobile = useDeviceAndOrientation()['device'] == 'mobile';
  const discoveryBtnRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={style.searchboxContainer}>
      <div className={style.searchboxLeft}>
        <div onClick={() => setIsShowUserProfileModal(!isShowUserProfileModal)}>
          <div className={style.userWithName}>
            <div className={style.userInitialsContainer}>
              <p className={style.userInitials}>{getUserInitials(user)}</p>
            </div>
            <p className={style.mHide}>{getUserName(user)}</p>
          </div>
          <AccountSettingsModal
            isOpen={isShowUserProfileModal}
            onCloseCB={() => setIsShowUserProfileModal(false)}
          />
        </div>
      </div>
      <div className={style.searchboxRight}>
        <div className={classNames(style.searchboxInner)}>
          <Button
            variant="primary"
            className={style.discoverBtn}
            onClick={() => searchProvider?.discovery.perform()}
            disabled={searchProvider?.discovery.isDisabled()}
            ref={discoveryBtnRef}
          >
            {searchProvider?.discovery.getButtonText()}
          </Button>
          {!isMobile && discoveryBtnRef.current && (
            <QuestionTooltip
              text={
                searchProvider?.discovery.isDisabled()
                  ? 'Hang tight! I will be ready in ~30 sec'
                  : searchProvider?.discovery.getTooltip() || ''
              }
              leftOffset={discoveryBtnRef.current?.offsetWidth}
            />
          )}
        </div>
      </div>
    </div>
  );
}
