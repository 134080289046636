import style from './style.module.css';

interface QuestionTooltipProps {
  text: string;
  leftOffset?: number;
}

export function QuestionTooltip(props: QuestionTooltipProps) {
  return (
    <div
      className={style.tooltipContainer}
      style={{
        ['--leftOffset' as string]: `${props.leftOffset ? props.leftOffset + 4 : props.leftOffset}px`,
      }}
    >
      <div className={style.bubble}>{props.text}</div>
    </div>
  );
}
